import React, { Fragment } from "react"
import style from "./cadastro.module.css";
import { Paper } from "@material-ui/core"
import brand from "../img/logo-brand.png";
import ReactPixel from "react-facebook-pixel";

export default () => {
  ReactPixel.fbq('track','PageViewConcluido');
  return (
    <Fragment>
    <div className={style.containerMain}>
      <div className={style.containImgBg}></div>
      <div className={style.containerContain}>
        <div className={style.containerForm}>
          <img src={brand} alt="akisuafeira" className={style.brand}></img>

          <Paper style={{ padding: 16 }}>
            <h2>Parabéns! seu cadastro foi concluido.</h2>
            <h4>Acesse o nosso catálogo e faça o seu pedido.</h4>
            <a className={style.linkButton} href={"https://catalogo.akisuafeira.com.br/"}>Catálogo</a>
          </Paper>
        </div>
      </div>
    </div>
  </Fragment>
  );
}

